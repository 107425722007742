import React from "react";
import Create, {Props} from "@/pages/create/wireguard";
import {graphql} from "gatsby";

const CreateOpenVpn: React.FunctionComponent<Props> = (props: Props) => <Create {...props}/>;

export default CreateOpenVpn;

export const query = graphql`
	query {
		site {
			siteMetadata {
				title
				canonical
				description
			}
		}
	}
`;
